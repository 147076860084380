.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
    background: white; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: relative;
  height: 500px; /* Kann bei Bedarf angepasst werden */
  background-image: url('/header_bild.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.separator {
  width: 50%; /* Breite des Strichs (z.B. 50% der Containerbreite) */
  height: 2px; /* Höhe des Strichs */
  background: linear-gradient(to right, transparent, #e85f5f, transparent); /* Leichtes Rot mit Gradient-Effekt */
  margin: 5% auto; /* Zentriert den Strich und gibt etwas Abstand */
}
.headline{
  margin: 5%;
}
.button {
  margin: 0 20px;
  padding: 12px 32px;
  background-color: rgba(0, 0, 0, 0.5); /* Halbtransparenter schwarzer Hintergrund */
  border: 2px solid white; /* Weiße Umrandung */
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Dunkler Schatten */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Etwas hellerer Hintergrund beim Hover */
  transform: translateY(-2px);
}

.button:active {
  background-color: rgba(255, 255, 255, 0.3); /* Noch etwas hellerer Hintergrund beim Klicken */
  transform: translateY(0);
}

.button-container .button {
  margin: 0 20px;
  padding: 12px 32px;
  background-color: #E57373; /* Das leichte Rot aus deinem Bild */
  border: none; /* Keine Umrandung */
  border-radius: 8px; /* Leicht abgerundete Ecken, wie im Bild */
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  box-shadow: none; /* Kein Schatten, um es dem Bild anzupassen */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-container .button:hover {
  background-color: #EF5350; /* Etwas dunkleres Rot beim Hover, um es deutlicher zu machen */
  transform: translateY(-2px);
}

.button-container .button:active {
  background-color: #E53935; /* Noch dunklerer Hintergrund beim Klicken */
  transform: translateY(0);
}




.content {
  padding: 20px;
}

.section {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.image {
  width: 60%;
  height: auto;
  padding: 0; 
  
}

.text {
  padding: 20px;
  margin-left: 0%;
  font-size: 19px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 50%;
  text-align: left;
  
  background: rgba(255, 255, 255, 0.8); /* Halbtransparenter weißer Hintergrund */

  color: black; /* Schwarze Schriftfarbe */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 150, 150, 0.5);
}

.image {
  background-color: #f0f0f0;
  text-align: center;
  width: 50%; 
  height: auto;
  
}
.icon-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-header img.icon {
  width: 30px; /* Größe des Icons anpassen */
  height: 30px; /* Größe des Icons anpassen */
  margin-right: 10px; /* Abstand zwischen Icon und Überschrift */
}


.modal {
  display: none; /* Hidden by default */
  position: relative; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 60px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Breite des Modals */
  max-width: 800px; /* Maximale Breite für das Modal */
}

.video-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 56.25%; /* Verhältnis für 16:9 Videos */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}




.footer {
  background-color: #333;
  color: white;
  padding: 10px 0;
  position: relative; /* Sicherstellen, dass der Footer nicht fixiert ist */
  width: 100%;
  text-align: center;
  z-index: 1; /* Footer hat einen niedrigen z-index */
}

.footer-links {
  list-style-type: none; /* Entfernt die Punkte vor den Links */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: white; /* Setzt die Linkfarbe auf weiß */
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.modal {
  display: none;
  position: fixed; /* Ändert das Modal auf fixed, damit es über dem Inhalt liegt */
  z-index: 1000; /* Sehr hoher z-index, um sicherzustellen, dass das Modal über allem liegt */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: white; 
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.image img {
  max-width: 100%; /* Bild passt sich der Breite des übergeordneten Containers an */
  height: auto;    /* Höhe passt sich proportional an */
  display: block;
  margin: 0 auto;  /* Bild wird zentriert */
}

.text {
  padding: 20px;
  margin-left: 0%;
  font-size: 19px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 50%;
  text-align: left;
  background: rgb(233, 233, 233);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 150, 150, 0.5);
}






/* Mobile Styles */
@media (max-width: 768px) {

  .header {
    background-image: url('/header_bild.jpg'); /* Mobiler Hintergrund */
    height: 300px; /* Höhe kann bei Bedarf angepasst werden */
    background-size: cover; /* Deckt den gesamten Bereich ab */
    background-position: center center; /* Zentriert das Bild im sichtbaren Bereich */
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding: 20px;
  }

  /* Spezifische Anpassung für das Bild in der ersten Section */
  .section:nth-of-type(1) .image img {
    content: url('./bild_1_mobil.jpg'); /* Verwende das mobile Bild für die erste Section */
    object-fit: cover; /* Sorgt dafür, dass das Bild den Container füllt, ohne gestreckt zu werden */
    width: 100%; /* Bild auf 100% der Breite setzen */
  }

  /* Spezifische Anpassung für das Bild in der zweiten Section */
  .section:nth-of-type(2) .image img {
    content: url('./bild_2_mobil.jpg'); /* Verwende das mobile Bild für die zweite Section */
    object-fit: cover; /* Sorgt dafür, dass das Bild den Container füllt, ohne gestreckt zu werden */
    width: 100%; /* Bild auf 100% der Breite setzen */
    
  }
  .section:nth-of-type(3) .image img {
    content: url('./bild_3_mobil.jpg'); /* Verwende das mobile Bild für die zweite Section */
    object-fit: cover; /* Sorgt dafür, dass das Bild den Container füllt, ohne gestreckt zu werden */
    width: 100%; /* Bild auf 100% der Breite setzen */
    
  }

  .section .image img {
    width: 100%; /* Bild in der mobilen Version anzeigen */
    display: block;
  }

  .image {
    width: 100%; 
    height: auto; 
    padding: 0;
  }

  /* Reihenfolge in der ersten Section */
  .section:nth-of-type(1) .text {
    order: 1; /* Text zuerst */
  }

  .section:nth-of-type(1) .image {
    order: 2; /* Bild nach dem Text */
  }

  /* Reihenfolge in der zweiten Section ändern */
  .section:nth-of-type(2) .text {
    order: 1; /* Text zuerst */
  }

  .section:nth-of-type(2) .image {
    order: 2; /* Bild nach dem Text */
  }

  .section:nth-of-type(3) .text {
    order: 1; /* Text zuerst */
  }

  .section:nth-of-type(3) .image {
    order: 2; /* Bild nach dem Text */
  }

  .text, .image {
    width: 100%; /* Nimmt die gesamte Breite ein */
    text-align: center;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px; /* Angemessene Schriftgröße für mobile */
    line-height: 1.5;
  }

  .separator {
    margin: 10% auto; /* Zentriert den Strich und gibt etwas Abstand */
  }

  .icon-header {
    flex-direction: column; /* Ändert die Ausrichtung auf vertikal */
    align-items: center; /* Zentriert das Icon und die Überschrift */
  }

  .icon-header img.icon {
    margin-right: 0; /* Entfernt den rechten Rand */
    margin-bottom: 5px; /* Fügt einen unteren Rand hinzu, um das Icon von der Überschrift zu trennen */
  }

  .footer-links {
    display: flex;
    flex-direction: column; /* Ändert die Anordnung auf vertikal */
    align-items: center; /* Zentriert die Links */
  }

  .footer-links li {
    margin: 10px 0; /* Fügt Abstand zwischen den Links hinzu */
  }

  .footer-links a {
    font-size: 16px; /* Größere Schriftgröße für bessere Lesbarkeit auf Mobilgeräten */
    text-decoration: none;
  }
}

/* Desktop Styles */
@media (min-width: 769px) {

  .section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-position: center;
    padding: 50px; /* Abstand zum Rand, damit der Text nicht am Rand klebt */
    color: white; /* Textfarbe auf Weiß setzen für besseren Kontrast */
    position: relative;
  }
  .section .image img {
    display: none; /* Bild in der Desktop-Version ausblenden */
  }
  .section:nth-of-type(1) {
    background-image: url('./bild_1.jpg'); /* Hintergrundbild für den ersten Abschnitt */
  }
  
  .section:nth-of-type(2) {
    background-image: url('./bild_2.jpg'); /* Hintergrundbild für den zweiten Abschnitt */
  }
  
  .section:nth-of-type(3) {
    background-image: url('./bild_3.jpg'); /* Hintergrundbild für den dritten Abschnitt */
  }
  
  .text {
    max-width: 50%; /* Text nimmt 50% der Breite ein */
    z-index: 1; /* Sicherstellen, dass der Text über dem Hintergrundbild angezeigt wird */
  }
  
  .section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Dunkler Overlay für besseren Kontrast */
    z-index: 0; /* Overlay unter den Text setzen */
  }
  
  .image, .text {
    width: 50%; /* Jedes Element nimmt 50% der Breite ein */
  }

  .footer-links {
    display: flex;
    justify-content: space-around;
  }
}


/* Desktop Styles */
@media (min-width: 769px) {

  .section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-position: center;
    padding: 50px; /* Abstand zum Rand, damit der Text nicht am Rand klebt */
    color: white; /* Textfarbe auf Weiß setzen für besseren Kontrast */
    position: relative;
  }

  .section .image img {
    display: none; /* Bild in der Desktop-Version ausblenden */
  }

  .section:nth-of-type(1) {
    background-image: url('./bild_1.jpg'); /* Hintergrundbild für den ersten Abschnitt in der Desktop-Version */
  }

  .section:nth-of-type(2) {
    background-image: url('./bild_2.jpg'); /* Hintergrundbild für den zweiten Abschnitt */
  }

  .section:nth-of-type(3) {
    background-image: url('./bild_3.jpg'); /* Hintergrundbild für den dritten Abschnitt */
  }

  .text {
    max-width: 50%; /* Text nimmt 50% der Breite ein */
    z-index: 1; /* Sicherstellen, dass der Text über dem Hintergrundbild angezeigt wird */
    font-size: 17px; /* Verkleinerte Schriftgröße für Desktop */
    line-height: 1.6; /* Angemessene Zeilenhöhe */
  }

  .section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Dunkler Overlay für besseren Kontrast */
    z-index: 0; /* Overlay unter den Text setzen */
  }

  .image, .text {
    width: 50%; /* Jedes Element nimmt 50% der Breite ein */
  }

  .footer-links {
    display: flex;
    justify-content: space-around;
  }
}
